.storybook--modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    z-index: 999; /* Place the overlay above other content */
    opacity: 0.7;
}

/* Sidenav.css */
.storybook--sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: -250px; /* Offscreen to the left initially */
    background-color: white;
    padding: 1.5rem 1rem 1.5rem 1rem !important;
    transition: 0.3s;
    z-index: 1000;
}

.storybook--sidenav ul {
    list-style: none;
    padding: 0;
}

.storybook--sidenav li {
    margin-bottom: 10px;
}

.storybook--sidenav a {
    color: black;
    text-decoration: none;
}

.storybook--sidenav a:hover {
    text-decoration: underline;
}

.storybook--sidenav.open {
    width: 250px; /* Displayed width when open */
    left: 0; /* Position on screen */
}

.storybook--sidenav-header {
    justify-content: space-between !important;
}

.storybook--sidenav-menu-options {
    margin-top: 1.5rem;
}

.storybook--sidenav-log-out-container {
    position: absolute;
    bottom: 8rem;
    left: 0;
    margin-left: 1rem;
    margin-bottom: 3rem;
}

.storybook--sidenav-developed-by--container {
    position:absolute;
    text-align: center;
    bottom: 1rem;
    left: 0;
    margin-left: 0.5rem;
    margin-bottom: 3rem;
}

.storybook--sidenav-links-container {
    position:absolute;
    text-align: center;
    bottom: 4rem;
}

.storybook--text-underline {
    text-decoration: underline;
}