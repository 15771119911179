.icon-svg-container div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-svg-container div svg {
    width: 1.5rem;
    height: 1.5rem;
}

.icon-svg-container-label-position-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.icon-svg-container-label-position-right p {
    margin-top: 0.125rem;
}

.icon-svg-container-label-position-bottom p {
    margin-top: 0.5rem;
}

.icon-svg-container-big {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 5rem;
    height: 5rem;
}

.icon-svg-container-medium {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.5rem;
}

.icon-svg-container-big-small {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
}

.icon-svg-container-small path {
    width: 1.5rem;
    height: 1.5rem;
}
