.image--container {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scroll-behavior: smooth;
}

.images--container img {
    max-height: 150px; /* Ajusta el tamaño de las imágenes según tus necesidades */
    margin-right: 10px; /* Espaciado entre las imágenes */
    border-radius: 0.5rem;
}