.storybook--input-text-container {
    margin: 0.5rem 0rem 0.5rem 0rem;
    width: 100%;
}

.storybook--input-group {
    display: flex;
}

.storybook--input-label {
    font-size: 0.875rem;
    color: rgb(44, 44, 44);
    margin-bottom: 0.5rem;
    font-family: "Figtree", "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-left: 0.5rem;
    text-align: left;
}

.storybook--input {
    width: 100%;
    height: 2.5rem;
    font-size: 1rem;
    border: 0;
    border-radius: 0.5rem;
    border: 1px solid rgb(110, 110, 110);
    font-family: "Figtree", "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-left: 0.5rem;
    padding-right: 2.5rem;
}

.storybook--input-error {
    border-color: rgb(255, 0, 15);
}

.storybook--input-text-container p:last-child {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    color: rgb(255, 0, 15);
}

.storybook--input-date-noarrow {
    appearance: none; /* Remove default styles */
    -webkit-appearance: none; /* For WebKit browsers */
    -moz-appearance: none; /* For Firefox */

    /* Hide the calendar picker indicator and spinner arrows */
    ::-webkit-calendar-picker-indicator {
        display: none;
    }

    ::-webkit-inner-spin-button {
        display: none;
    }
}

.storybook--input-date-nocalendar::-webkit-inner-spin-button,
.storybook--input-date-nocalendar::-webkit-calendar-picker-indicator {
    display: none;
}

.storybook--input-date-nocalendar::-webkit-clear-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
}
