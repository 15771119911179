.storybook--loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    z-index: 999; /* Place the overlay above other content */
    opacity: 0.7;
}

.storybook--loader-container {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the loader */
    text-align: center;
    z-index: 1000;
}

.storybook--loader-container img {
    width: 7rem;
    height: 7rem;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
