.storybook--header-color-beta {
    background-color: transparent;
}

.storybook--header-boxshadow {
    box-shadow: 0px 4px 10px rgba(255, 0, 92, 0.5);
}


.storybook--svg-wrapper-header-left-icon svg {
    height: 2rem;
    width: 2rem;
}

.storybook--header-avatar,
.storybook--header-button {
    margin-left: auto !important;
    margin-right: 2rem;
}

.storybook--header-main-logo-container {
    margin-left: 6.474rem;
}

.storybook--header-main-logo-container-unlogged {
    margin-left: 1rem;
}

.storybook--header-main-logo-container img {
    height: 2rem;
}

.home-image-logo {
    position: relative;
    right: 0;
}

.home-logged-components {
    margin-right: 2rem;
    margin-left: auto !important;
    gap: 1rem;
}

.header-has-notifications {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #FF005C;
    border-radius: 1rem;
    position: absolute;
    float: right;
    margin-top: -1rem;
    margin-left: 1.5rem;
}

nav ul {
    list-style: none;
    display: flex;
}

nav ul li {
    width: 10rem;
}

nav ul li a {
    text-decoration: none;
    color: black;
    font-size: 18px;
}

nav ul li a:hover {
    color: white;
    background-color: #FF005C;
    border-radius: 1rem;
    padding: 0.5rem;
}

.storybook--header-nav-menu {
    margin-bottom: 0rem;
}

/* Estilos para menú responsivo */
.menu-toggle {
    display: none;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.w-100 {
    height: 45rem !important;
}

.carousel-caption {
    background-color: black;
    color:white;
    opacity: 0.8;
    bottom: 0.5rem !important;
    right: 0% !important;
    left: 0% !important;
}


@media (max-width: 768px) {
    nav ul {
        display: none;
        flex-direction: column;
        background-color: #333;
        position: absolute;
        top: 60px;
        right: 0;
        width: 100%;
        text-align: center;
    }

    nav ul li {
        margin: 15px 0;
    }

    .menu-toggle {
        display: block;
    }

    .active {
        display: flex;
    }
}