.onboarding--container {
    height: 100vh;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 1.125rem;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 12rem;
    height: 28rem;
    object-fit: cover;
}

.swiper--image {
    padding: 1.25rem 0rem 0.5rem 0rem;
    height: 8rem !important;
    width: 20rem !important;
}

.swiper--padding{
    padding: 1.25rem 1rem 1rem rem;
    margin: 1rem;
}

.swiper--image3 {
    padding: 1.25rem 0rem 0.5rem 0rem;
    height: 20rem !important;
    width: 20rem !important;
}

.swiper--image4 {
    padding: 1.25rem 0rem 0.5rem 0rem;
    height: 17rem !important;
    width: 22rem !important;
}

