.storybook--hr {
    border-bottom: none;
}

.storybook--hr-solid {
    border-top: 0.063rem solid;
}

.storybook--hr-dashed {
    border-top: 0.063rem dashed;
}
