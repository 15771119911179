body {
    /*padding-left: 1.063rem;
    padding-right: 1.063rem;*/
    padding: 0;
    margin: 0;
    letter-spacing: 0;
    font-family: "Figtree", "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

header {
    position: fixed;
    top: 0rem;
    left: 0rem;
    width: 100vw;
    margin: auto 0;
    display: flex;
    text-align: center;
    padding: 1rem;
    align-items: center;
}

footer {
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    width: auto;
    margin: auto 0;
    background-color: transparent;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.storybook--center-elements {
    display: flex;
    justify-content: center;
    align-items: center;
}

.storybook--steps-text-container {
    justify-content: space-between;
    padding-top: 0.5rem;
}

.storybook--container-flex {
    display: flex;
}

.storybook--container-flex-space-between {
    justify-content: space-between;
    gap: 0.5rem;
}

.storybook--container-flex-gap-sm {
    gap: 0.5rem;
}

.storybook--container-flex-align-items-center {
    align-items: center;
}

.storybook--padding-left-none {
    padding-left: 0rem;
}

.storybook--padding-right-none {
    padding-right: 0rem;
}

.storybook--margin-left-xs {
    margin-left: 0.5rem;
}

.storybook--margin-left-sm {
    margin-left: 1rem;
}

.storybook--margin-left-md {
    margin-left: 2rem;
}

.storybook--margin-right-xs {
    margin-right: 0.5rem;
}

.storybook--margin-right-sm {
    margin-right: 1rem;
}

.storybook--margin-right-md {
    margin-right: 2rem;
}

.storybook--margin-top-xs {
    margin-top: 0.5rem;
}

.storybook--margin-top-sm {
    margin-top: 1rem;
}

.storybook--margin-bottom-xs {
    margin-bottom: 0.5rem;
}

.storybook--margin-bottom-sm {
    margin-bottom: 1rem;
}

.storybook--margin-bottom-md {
    margin-bottom: 2rem;
}

.storybook--margin-bottom-lg {
    margin-bottom: 6rem;
}

.storybook--full-width {
    width:100%;
}

.storybook--hide-default-appearance select{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.storybook--text-align-right {
    text-align: right !important;
}

.storybook--text-align-center {
    text-align: center !important;
}

.storybook--tag-element-max-index {
    z-index: 999;
}

.storybook--footer-default-padding {
    padding-left: 1.063rem;
    padding-right: 1.063rem;
}

.storybook--general-default-padding {
    padding-left: 1.063rem;
    padding-right: 1.063rem;
}

.storybook--general-cursor-pointer {
    cursor: pointer;
}
