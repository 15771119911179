.storybook-button {
    width: 100%;
    height: 2.5rem;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    font-family: "Figtree", "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    border: 0;
    border-radius: 0.5rem;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    margin: 0.625rem 0rem 0.625rem 0rem;
}

.storybook-button p {
    width: -webkit-fill-available;
}

.storybook-button--small {
    width: 8rem;
    height: 2rem;
    font-size: 0.75rem;
    padding: 1rem 0.5rem;
    margin: 0;
}

.storybook-button--chip {
    width: auto;
    height: 1.5rem;
    font-size: 0.75rem;
    padding: 0rem 0.5rem;
    margin: 0;
    border-radius: 1rem;
    align-items: center;
}

.storybook-button--icon {
    display: flex;
}

.storybook-button--primary {
    color: white;
    background-color: rgb(255, 0, 92);
}

.storybook-button--primary-outline {
    color: rgb(255, 0, 92);
    border: 1px solid rgb(255, 0, 92);
    background-color: rgb(255, 255, 255);
}

.storybook-button--primary-unoutline {
    color: rgb(255, 0, 92);
    background-color: rgb(255, 255, 255);
}

.storybook-button--disabled {
    color: rgb(255, 255, 255);
    background-color: rgb(170, 177, 188);
}

.storybook-button--disabled-outline {
    color: rgb(170, 177, 188);
    border: 1px solid rgb(170, 177, 188);
    background-color: rgb(255, 255, 255);
}

.storybook-button--disabled-outline-nobackground {
    color: rgb(170, 177, 188);
    border: 1px solid rgb(170, 177, 188);
    background-color: transparent;
}

.storybook-button--danger-outline {
    color: rgb(255, 0, 15);
    border: 1px solid rgb(255, 0, 15);
    background-color: rgb(255, 255, 255);
}

.storybook-button--action-outline {
    color: rgb(7, 91, 50);
    border: 1px solid rgb(7, 91, 50);
    background-color: rgb(255, 255, 255);
}

.storybook-button--chip-cancelled {
    color: #2c2c2c;
    border: 1px solid #ffd3cf;
    background-color: #ffd3cf;
}

.storybook-button--chip-success {
    color: #2c2c2c;
    border: 1px solid #ddffe6;
    background-color: #ddffe6;
}

.storybook-button--chip-warning {
    color: #2c2c2c;
    border: 1px solid #fdf7d4;
    background-color: #fdf7d4;
}
