.not-available-image-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    width: 22rem;
    margin-top: 10rem;
}

.not-available-margin {
   margin: 2rem;
}

.not-available-margin-h2 {
    text-align: center;
    margin: 1.5rem;
    width: 20rem;
 }

 .not-available-margin-p {
   display: block;
   text-align: center;
   margin: 1rem;
   width: 20rem;
 }