.storybook--modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    z-index: 999; /* Place the overlay above other content */
    opacity: 0.7;
}

.storybook--modal-container {
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the loader */
    text-align: center;
    z-index: 1000;
    width: 18.375rem;
}

.storybook--modal-info-container {
    margin-top: 0.875rem;
    margin-bottom: 1rem;
}

.storybook--modal-info-container p:last-child {
    margin-top: 1rem;
    line-height: 1;
}
