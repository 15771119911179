.storybook--card {
    border-radius: 0.5rem;
    min-height: 3.125rem;
    width: -webkit-fill-available;
    padding: 1rem;
    margin-top: 1rem;
}

.storybook--card p {
    line-height: 1;
}
