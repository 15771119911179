.home {
    margin-top: 2rem;
    padding-left: 1.063rem;
    padding-right: 1.063rem;
}

.home--header {
    display: none;
    justify-content: center;
}
.header-left-icon-menu {
    display: none;
}

.header--color-container {
    width: 100%;
    height:45rem;
    background: linear-gradient(to bottom, #FFE5EF, #FF005C);
    position:relative;
    img {
        width: 37rem;
    }
}

.header--color-container-items {
    position: absolute;
    bottom: 2rem;
    img {
        width:35rem;
    }
}

.home-title {
    padding: 2rem;
    background-color: #FF005C;
    opacity: 0.9;
    line-height: 1;
}

@media (max-width: 768px) or (max-width: 1000px){
    .header--color-container-items {
        bottom: 10rem;
    }
}




@media (max-width: 768px) {
    .home--header {
        justify-content: left !important;
    }
    .header-left-icon-menu {
        display: inline-block;
    }    
}

/*IMAGES SECTION*/
.home--images-container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    transform: translateY(50px);
    transition:
        opacity 1s ease-out,
        transform 1s ease-out;
}

.home--images-container.show {
    opacity: 1;
    transform: translateY(0);
}

.text-section {
    width: 50%;
    padding: 20px;
}

.image-section {
    width: 50%;
}

.image-section img {
    width: 35rem;
    border-radius: 3rem;
}

/* Estilos para hacer el scroll más fluido */
.spacer {
    height: 100vh;
}

.border-left-text {
    border-left: 2px solid #FF005C;
}

.border-right-text {
    border-right: 2px solid #FF005C;
}


@media (max-width: 768px) or (max-width: 1000px){
    .home--images-container {
        width: 100%;
    }
    .text-section {
        width: 100%;
        padding: 0px;
        text-align: justify;
    }

    .hide-image {
        display: none;
    }

    .image-section {
        display: none !important;
    }

    .border-left-text {
        border: none;
    }

    .border-right-text {
        border: none;
    }
}

/*FIN IMAGES SECTION*/

/*BENEFITS SECTION*/

@media (max-width: 768px) {
    .home--benefits-container {
        display: block !important;
    }
}

/*FIN BENEFITS SECTION*/

/*PACKAGE SECTION*/
.home--packages-container-package {
    box-shadow: 0px 0px 6px rgba(255, 0, 92, 0.3);
}

.home--packages-container-package .icon-svg-container-label-position-right {
    justify-content: left !important;
}

.home--packages-price-period {
    margin-top: 1rem;
}

.home--packages--package-button:hover {
    color: white;
    background-color: rgb(255, 0, 92);
}

@media (max-width: 768px) {
    .home--packages-container {
        display: block !important;
    }
}

/*FIN PACKAGE SECTION*/

/*FOOTER SECTION*/
.text--line-height-xs {
    line-height: 1rem !important;
    font-weight: 500;
}

.text--line-height-md {
    line-height: 2.5rem !important;
    font-weight: 500;
}

.text--line-height {
    line-height: 1.5rem !important;
    font-weight: 500;
}

.footer-card-height {
    min-height: 20rem !important;
}

.footer-card-contact {
    justify-content: left;
}

.footer-link:hover {
    color:#FF005C;
    cursor: pointer;
}

@media (max-width: 768px) {
    .footer-card-height {
        min-height: 12rem !important;
    }

    .home--footer-container {
        display: block !important;
    }
}

/*FIN FOOTER SECTION*/

.store-button {
    display: block;
    margin: 10px;
}

.store-icon {
    width: 130px; /* Ajusta el tamaño según necesites */
    height: auto;
}
