.faq--container {
    margin-top: 3.5rem;
}

.faq--container-padding{
    margin: 1rem;
    padding-top: 3.5rem;
}

.faq--container-padding p {
    padding: 0.2rem;
    span {
        font-family: "Figtree", "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    }
}