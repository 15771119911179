ul,
#myUL {
  list-style-type: none;
}

#myUL {
  margin: 10px;
  padding: 10px;
}

.box {
  /*cursor: pointer;*/
  /* -webkit-user-select: none; Safari 3.1+ */
  /* -moz-user-select: none;  Firefox 2+ */
  /* -ms-user-select: none;  IE 10+ */
  /*user-select: none;*/
}

/*
.box::before {
  content: '\2610';
  color: black;
  display: inline-block;
  margin-right: 6px;
}
*/

/*
.check-box::before {
  content: '\2611';
  color: dodgerblue;
}
*/

.nested {
  display: none;
}

.active {
  display: block;
}
