.storybook--avatar {
    border-color: rgb(250, 251, 255);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 100%;
}

.storybook--avatar-small {
    width: 2.5rem;
    height: 2.5rem;
    border: 0.063rem solid;
}

.storybook--avatar-medium {
    width: 5.5rem;
    height: 5.5rem;
    border: 0.25rem solid;
}

.storybook--avatar-big {
    width: 7.5rem;
    height: 7.5rem;
    border: 0.25rem solid;
}
