.storybook--color-alpha {
    color: rgb(64, 64, 64); /*#404040*/
}

.storybook--color-beta {
    color: rgb(255, 255, 255); /*#FFFFFF*/
}

.storybook--color-gamma {
    color: rgb(255, 0, 92); /*#FF005C*/
}

.storybook--color-delta {
    color: rgb(170, 0, 61); /*#AA003D*/
}

.storybook--color-epsilon {
    color: rgb(170, 177, 188); /*#AAB1BC*/
}

.storybook--color-psi {
    color: rgb(241, 242, 248); /*#F1F2F8*/
}

.storybook--color-eta {
    color: rgb(255, 0, 15); /*#FF000F*/
}

.storybook--color-sigma {
    color: rgb(67, 217, 97); /*#43D961*/
}

.storybook--color-tau {
    color: rgb(221, 255, 230); /*#DDFFE6*/
}

.storybook--color-iota {
    color: rgb(255, 157, 0); /*#FF9D00*/
}

.storybook--color-phi {
    color: rgb(110, 110, 110); /*#6E6E6E*/
}

.storybook--color-omega {
    color: rgb(241, 242, 248); /*#F1F2F8*/
}

.storybook--color-zeta {
    color: rgb(250, 251, 255); /*#FAFBFF*/
}

.storybook--color-rho {
    color: rgb((0, 0, 0)); /*#D9D9D9*/
}

.storybook--color-kappa {
    color: rgb(217, 217, 217); /*#D9D9D9*/
}

.storybook--color-nil {
    color: transparent;
}

.storybook--text {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.25rem;
}

.storybook--text-h1 {
    font-size: 2.125rem;
    font-weight: bold;
}

.storybook--text-h2 {
    font-size: 1.375rem;
    font-weight: bold;
}

.storybook--text-h3 {
    font-size: 1.25rem;
    font-weight: bold;
}

.storybook--text-h4 {
    font-size: 1rem;
    font-weight: bold;
}

.storybook--text-h5 {
    font-size: 0.875rem;
    font-weight: bold;
}

.storybook--text-h6 {
    font-size: 0.75rem;
    font-weight: bold;
}

.storybook--text-s1 {
    font-size: 1rem;
    font-weight: 500;
}

.storybook--text-s2 {
    font-size: 0.875rem;
    font-weight: 500;
}

.storybook--text-s3 {
    font-size: 0.75rem;
    font-weight: 500;
}

.storybook--text-s4 {
    font-size: 0.688rem;
    font-weight: 500;
}

.storybook--text-b1 {
    font-size: 1rem;
    font-weight: 400;
}

.storybook--text-b2 {
    font-size: 0.875rem;
    font-weight: 400;
}

.storybook--text-b3 {
    font-size: 0.75rem;
    font-weight: 400;
}

.storybook--text-b4 {
    font-size: 0.688rem;
    font-weight: 400;
}

.storybook--center-elements {
    display: flex;
    justify-content: center;
    align-items: center;
}

.storybook--steps-text-item {
    text-align: center;
    width: 4.5rem;
}

.storybook--steps-text-item:first-child {
    text-align: left !important;
}

.storybook--steps-text-item:last-child {
    text-align: right !important;
}

.storybook--data-item:last-child {
    padding: 0.25rem 0rem 0.5rem 0rem;
}